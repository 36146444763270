(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/statistics-common/assets/use-external-match-id.js') >= 0) return;  svs.modules.push('/components/sport/statistics-common/assets/use-external-match-id.js');
"use strict";


const {
  useEffect,
  useMemo
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  selectSportEvent,
  selectExternalId
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  ReducerState
} = svs.components.sport.tipsenShared;
const {
  fetchMatchData
} = svs.components.sportinfo.matchInfoRedux.actions;
const useExternalMatchIds = matchId => {
  const dispatch = useDispatch();
  const match = useSelector(state => selectSportEvent(state, matchId));
  const loading = !match || !match.dataState || (match === null || match === void 0 ? void 0 : match.dataState) === ReducerState.Init || (match === null || match === void 0 ? void 0 : match.dataState) === ReducerState.Pending;
  const rejected = !loading && (match === null || match === void 0 ? void 0 : match.dataState) === ReducerState.Rejected;
  const done = !loading && (match === null || match === void 0 ? void 0 : match.dataState) === ReducerState.Done;
  const externalId = useSelector(state => selectExternalId(state, matchId));
  useEffect(() => {
    const hasExternalId = externalId ? Object.keys(externalId).length > 0 : false;
    if (matchId && !hasExternalId) {
      dispatch(fetchMatchData(matchId));
    }
  }, [matchId, externalId, dispatch]);
  return useMemo(() => ({
    loading,
    rejected,
    done,
    externalId
  }), [loading, rejected, done, externalId]);
};
setGlobal('svs.components.sport.statisticsCommon.useExternalMatchIds', useExternalMatchIds);

 })(window);