(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/statistics-common/assets/external-match-id.js') >= 0) return;  svs.modules.push('/components/sport/statistics-common/assets/external-match-id.js');
"use strict";

const {
  logger,
  useExternalMatchIds
} = svs.components.sport.statisticsCommon;

const ExternalMatchId = _ref => {
  let {
    branding,
    matchId,
    type,
    children,
    FallbackComp
  } = _ref;
  const {
    loading,
    rejected,
    externalId
  } = useExternalMatchIds(matchId);
  if (loading) {
    return children(undefined, loading);
  } else if (rejected) {
    return 'Något gick fel';
  } else if (!externalId[type]) {
    logger.info("Missing external match id for match: ".concat(matchId, " type: ").concat(type));
    if (FallbackComp) {
      return React.createElement(FallbackComp, {
        branding: branding
      }, "Inget att visa");
    }
    return null;
  }
  return children(externalId[type]);
};
setGlobal('svs.components.sport.statisticsCommon.ExternalMatchId', ExternalMatchId);

 })(window);