(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/statistics-common/assets/prescript.js') >= 0) return;  svs.modules.push('/components/sport/statistics-common/assets/prescript.js');
"use strict";

const {
  log
} = svs.core;
const logger = log.getLogger('component:statistics-common');
setGlobal('svs.components.sport.statisticsCommon', {
  logger
});

 })(window);